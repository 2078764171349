import React, { useEffect, useRef } from "react";
import Navbar from "./Components/Navbar/Navbar";
import Styles from "./App.module.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Box } from "@mui/material";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import Contact from "./Components/Contact/Contact";
// import Certification from "./Components/CertificationPage/Certification";
// import ProductPage from "./Components/ProductsPage/ProductPage";
// import ServicePage from "./Components/ServicePage/ServicePage";
// import ContactUs from "./Components/ContactUs/ContactUs";

const App = () => {
  const faqRef = useRef<HTMLDivElement | null>(null);
  const clientRef = useRef<HTMLDivElement | null>(null);
  const storyRef = useRef<HTMLDivElement | null>(null);
  const serviceRef = useRef<HTMLDivElement | null>(null);
  const productRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToFaqSection = () => {
    if (location.pathname !== "/") {
      navigate("/");
      faqRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    faqRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToClientRefSection = () => {
    if (location.pathname !== "/") {
      navigate("/");
      clientRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    clientRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToStoryRefSection = () => {
    if (location.pathname !== "/") {
      navigate("/");
      storyRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    storyRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToServiceRefSection = () => {
    if (location.pathname !== "/") {
      navigate("/");
      serviceRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    serviceRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToProductRefSection = () => {
    if (location.pathname !== "/") {
      navigate("/");
      productRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    productRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box className={Styles.main_container}>
        <Box className={Styles.nav_container}>
          <Navbar
            scrollToFaqSection={scrollToFaqSection}
            scrollToClientRefSection={scrollToClientRefSection}
            scrollToStoryRefSection={scrollToStoryRefSection}
            scrollToServiceRefSection={scrollToServiceRefSection}
            scrollToProductRefSection={scrollToProductRefSection}
          />
        </Box>
        <Box>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  faqRef={faqRef}
                  clientRef={clientRef}
                  storyRef={storyRef}
                  serviceRef={serviceRef}
                  productRef={productRef}
                />
              }
            />
            <Route path="/contactus" element={<Contact />} />
          </Routes>
        </Box>
        <Box className={Styles.footer_container}>
          <Footer
            scrollToFaqSection={scrollToFaqSection}
            scrollToClientRefSection={scrollToClientRefSection}
            scrollToStoryRefSection={scrollToStoryRefSection}
            scrollToServiceRefSection={scrollToServiceRefSection}
            scrollToProductRefSection={scrollToProductRefSection}
          />
        </Box>
      </Box>
    </>
  );
};

export default App;

import React, { useEffect, useRef, useState } from "react";
import Styles from "./VisionSection.module.css";
import { Box, Stack } from "@mui/material";
import MissionImage1 from "../../../assests/Safety_v1.1 (1).mp4";
import MissionImage2 from "../../../assests/Quality (1).mp4";
import MissionImage3 from "../../../assests/Efficacy (1).mp4";

const VisionSection = () => {
  const [value, setValue] = useState(1);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  
  const videoSrc = value === 1 ? MissionImage1 : value === 2 ? MissionImage2 : MissionImage3;
  
  useEffect(() => {
    const videoElement = videoRef.current; 
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.unobserve(entry.target); 
        }
      },
      { threshold: 0.5 }
    );
  
    if (videoElement) {
      observer.observe(videoElement);
    }
  
    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);

  const visionData = [
    {
      value: 1,
      title: "We prioritise <span>safety</span>",
      content:
        "We use clean ingredients to ensure safety and minimize side effects, while also maintaining a long shelf life for effectiveness.",
    },
    {
      value: 2,
      title: "We deliver <span>quality</span>",
      content:
        "With a state-of-the-art facility and experienced scientists, we prioritize delivering quality to our customers.",
    },
    {
      value: 3,
      title: "we formulate for <span>efficacy</span>",
      content:
        "Our ingredients are sourced from more than 20 countries globally, with a focus on meticulous formulation and rigorous testing to deliver effective results.",
    },
  ];



  const handleChangePoster = (value: any) => {
    setValue(value);
  };

  return (
    <>
      <Box className={Styles.vision_container}>
        <Box className={Styles.vision_container_title}>
          Your vision <span>Our Promise</span>
        </Box>
        <Box className={Styles.vision_container_content}>
          <Stack className={Styles.left_content} height="auto">
            <Stack className={Styles.left_video} height="100%" width="100%">
              <video
                
                  ref={videoRef}
                  src={isLoaded ? videoSrc : undefined}
                muted
                loop
                autoPlay
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                onCanPlay={() => console.log("Video loaded successfully")}
              />
              <Stack className={Styles.poster_name}>
                {/* <Stack
                  className={Styles.poster_name_text}
                  sx={{ display: { xs: "none", md: "flex" } }}
                >
                  Your Vision is <span>Our Mission</span>
                </Stack> */}
              </Stack> 
            </Stack>
          </Stack>

          <Stack className={Styles.right_content}>
            {visionData.map((item, index) => (
              <Stack
                key={item.value}
                className={Styles.content}
                sx={{
                  borderLeft:
                    value === item.value
                      ? "6px solid #7CF169"
                      : "6px solid #d9d9d9",
                  transition: "border-left 0.3s ease",
                }}
              >
                <Stack
                  className={Styles.single_content}
                  sx={{
                    borderBottom: index < 2 ? "2px solid #fff" : "none",
                    opacity: value !== item.value ? "0.3" : "1",
                    transition: "opacity 0.3s ease",
                  }}
                  onClick={() => handleChangePoster(item.value)}
                >
                  <Stack
                    className={Styles.content_title}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  ></Stack>
                  <Stack className={Styles.content_info}>{item.content}</Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default VisionSection;

import React, { useEffect, useRef, useState } from "react";

interface LazyLoadImageProps {
  src: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
  onLoadCallback?: () => void;
}

const LazyLoadImage: React.FC<LazyLoadImageProps> = ({
  src,
  alt,
  className,
  style,
  onLoadCallback,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const imgElement = imgRef.current; // Store imgRef.current in a local variable

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (imgElement) {
      observer.observe(imgElement);
    }

    return () => {
      if (imgElement) {
        observer.unobserve(imgElement);
      }
    };
  }, []);

  return (
    <img
      ref={imgRef}
      src={isLoaded ? src : undefined}
      alt={alt}
      className={className}
      style={style}
      onLoad={onLoadCallback}
    />
  );
};

export default LazyLoadImage;

import React, { useState, useEffect, useRef } from "react";
import Styles from "./Navbar.module.css";
import { Box, Stack, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import OnestoLogo from "./../../assests/onestoLogo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

interface NavbarProps {
  scrollToFaqSection: () => void;
  scrollToClientRefSection: () => void;
  scrollToStoryRefSection: () => void;
  scrollToServiceRefSection: () => void;
  scrollToProductRefSection: () => void;
}

const Navbar: React.FC<NavbarProps> = ({
  scrollToFaqSection,
  scrollToClientRefSection,
  scrollToStoryRefSection,
  scrollToServiceRefSection,
  scrollToProductRefSection,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [scroll, setScroll] = useState(false);
  const MenuRef = useRef<HTMLDivElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);



  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    const isClickOutsideMenu =
      MenuRef.current && !MenuRef.current.contains(event.target as Node);

    if (isClickOutsideMenu) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* <Box className={ Styles.navbar_container }> */}
      <Box
        className={`${Styles.navbar_container} ${
          scroll ? Styles.navbar_scrolled : ""
        }`}
      >
        {/* Logo */}
        <Stack className={Styles.navbar_logo_container}>
          <Stack
            className={Styles.navbar_logo}
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={OnestoLogo} alt="logo" />
          </Stack>
        </Stack>

        {/* Options */}
        <Stack
          className={Styles.navbar_options}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Stack
            className={`  ${
              location.pathname !== "/products"
                ? Styles.navbar_options_text
                : Styles.navbar_notmal_option
            }`}
            onClick={() => {
              scrollToProductRefSection();
            }}
          >
            Our Products
          </Stack>
          <Stack
            className={`  ${
              location.pathname !== "/certifications"
                ? Styles.navbar_options_text
                : Styles.navbar_notmal_option
            }`}
            onClick={() => {
              scrollToStoryRefSection();
            }}
          >
            Our Story
          </Stack>
          <Stack
            className={Styles.navbar_options_text}
            onClick={scrollToClientRefSection}
          >
            Our Clients
          </Stack>
          <Stack
            className={`  ${
              location.pathname !== "/services"
                ? Styles.navbar_options_text
                : Styles.navbar_notmal_option
            }`}
            onClick={() => {
              scrollToServiceRefSection();
            }}
          >
            Our Services
          </Stack>
          <Stack
            className={Styles.navbar_options_text}
            onClick={scrollToFaqSection}
          >
            FAQs
          </Stack>
        </Stack>
        {/* End */}

        {/* Contact button */}
        <Stack
          className={Styles.navbar_btn_container}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Stack
            className={Styles.navbar_btn}
            onClick={() => {
              navigate("/contactus");
            }}
          >
            Let's Connect
          </Stack>
        </Stack>
        {/* end */}

        <Stack className={Styles.menubar_btn}>
          <IconButton
            className={Styles.iconbutton}
            onClick={() => {
              setIsMenuOpen(true);
            }}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Stack>

        {/* Menu Options */}
        <Stack
          ref={MenuRef}
          className={Styles.drawer_options}
          sx={{
            display: isMenuOpen ? "block !important" : "none !important",
            background: "#F0F0F0",
          }}
        >
          <Stack className={Styles.drawer_head}>
            <Stack></Stack>
            <IconButton
              className={Styles.navbar_menu_button}
              onClick={() => {
                setIsMenuOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <Stack
            className={Styles.drawer_options_text}
            onClick={() => {
              scrollToStoryRefSection();
              setIsMenuOpen(false);
            }}
          >
            Our <span>Story</span>
          </Stack>
          <Stack
            className={Styles.drawer_options_text}
            onClick={() => {
              scrollToServiceRefSection();
              setIsMenuOpen(false);
            }}
          >
            Our <span>Services</span>
          </Stack>
          <Stack
            className={Styles.drawer_options_text}
            onClick={() => {
              scrollToProductRefSection();
              setIsMenuOpen(false);
            }}
          >
            Our <span>Products</span>
          </Stack>
          <Stack
            className={Styles.drawer_options_text}
            onClick={() => {
              scrollToClientRefSection();
              setIsMenuOpen(false);
            }}
          >
            Our <span>Clientele</span>
          </Stack>
          <Stack
            className={Styles.drawer_options_text}
            onClick={() => {
              scrollToFaqSection();
              setIsMenuOpen(false);
            }}
          >
            <span>FAQs</span>
          </Stack>
          <Stack
            className={Styles.drawer_options_text}
            onClick={() => {
              navigate("/contactus");
              setIsMenuOpen(false);
            }}
          >
            Connect <span>Us</span>
          </Stack>
        </Stack>
        {/*  */}
      </Box>
    </>
  );
};

export default Navbar;

import { Box, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Styles from "./Formulation.module.css";
import FormulationImg from "./../../../assests/Forumation-img.svg";
import IfraIcon from "./../../../assests/ifra.svg";
import CruelyIcon from "./../../../assests/cruely free.svg";
import GMPIcon from "./../../../assests/gmp.svg";
import CRcon from "./../../../assests/CRegis.svg";
import ContactUsModal from "../ContactModal/ContactUsModal";
import FormulationAccordion from "./FormulationAccordion";
import FirstVideo from "./../../../assests/Formulation videos/Comp 2.mp4";
import ThirdVideo from "./../../../assests/Formulation videos/hands-delivery-and-box-with-green-screen-for-ship-2024-10-03-19-30-20-utc_1.mp4";
import SecondVideo from "./../../../assests/Formulation videos/2.mp4";
import LazyLoadImage from "../../LazyLoadImageProps";


function LazyLoadMedia({
  src,
  type,
  onLoadCallback,
}: {
  src: string;
  type: "video" | "image";
  onLoadCallback?: () => void;
}) {
  const mediaRef = useRef<HTMLVideoElement | HTMLImageElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const mediaElement = mediaRef.current; // Save mediaRef.current to a local variable

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.unobserve(entry.target); // Stop observing after loading
        }
      },
      { threshold: 0.5 }
    );

    if (mediaElement) {
      observer.observe(mediaElement);
    }

    return () => {
      if (mediaElement) {
        observer.unobserve(mediaElement);
      }
    };
  }, []);

  return type === "video" ? (
    <video
      ref={mediaRef as React.RefObject<HTMLVideoElement>}
      src={isLoaded ? src : undefined}
      muted
      loop
      autoPlay
      style={{ width: "100%", height: "auto" }}
      onCanPlay={onLoadCallback}
    />
  ) : (
    <img
      ref={mediaRef as React.RefObject<HTMLImageElement>}
      src={isLoaded ? src : undefined}
      alt="Animated GIF"
      style={{ width: "100%", height: "auto" }}
      onLoad={onLoadCallback}
    />
  );
}

const FormulationSection = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(1);

  const toggleAccordion = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    if (activeIndex === null) {
      setActiveIndex(1);
    }
  }, [activeIndex]);

  

  const formulationSteps = [
    {
      no: 1,
      icon: "link",
      head: "Pick your ",
      title: "formulation",
    },
    {
      no: 2,
      icon: "link",
      head: "select your ",
      title: "packaging",
    },
    {
      no: 3,
      icon: "link",
      head: "Product delivery ",
      title: "in 60 days",
    },
  ];

  

  return (
    <>
      <Box className={Styles.formulation_section_container}>
        {/* Accordion Section */}
        <Stack className={Styles.formulation_section_part1}>
          <Stack className={Styles.left}>
            <Stack
              className={Styles.left_title}
              sx={{ display: { xs: "none", md: "flex" } }}
              marginTop={"5px"}
            >
               Here's Your Guide to <span style={{display:"contents"}}>Crafting Effective Product Innovations </span>with Us
            </Stack>
            <Stack className={Styles.left_accordion}>
              <FormulationAccordion
                serialNo="1"
                title="Pick your "
                subtitle="formulation"
                content="Use our extensive formulation repository to customize or create new formulations for your brand."
                isActive={activeIndex === 1}
                onToggle={() => toggleAccordion(1)}
              />
              <FormulationAccordion
                serialNo="2"
                title="Select your "
                subtitle="packaging"
                content="Choose the right packaging for your brand needs with a wide variety of options like sustainable bottles, airtight containers, eco-friendly plastics, and more."
                isActive={activeIndex === 2}
                onToggle={() => toggleAccordion(2)}
              />
              <FormulationAccordion
                serialNo="3"
                title="Product delivery in "
                subtitle="60 days"
                content="Once approved, our team manages everything, so you can prioritize marketing and launch."
                isActive={activeIndex === 3}
                onToggle={() => toggleAccordion(3)}
              />
            </Stack>
          </Stack>

          <Stack className={Styles.right}>
            {activeIndex === 1 ? (
              <Stack className={Styles.right_video}>
                <LazyLoadMedia
                  src={FirstVideo}
                  type="video"
                  onLoadCallback={() =>
                    console.log("First video loaded successfully")
                  }
                />
              </Stack>
            ) : activeIndex === 2 ? (
              <Stack className={Styles.right_video}>
                <LazyLoadMedia
                  src={SecondVideo}
                  type="video"
                  onLoadCallback={() => console.log("GIF loaded successfully")}
                />
              </Stack>
            ) : (
              <Stack className={Styles.right_video}>
                <LazyLoadMedia
                  src={ThirdVideo}
                  type="video"
                  onLoadCallback={() =>
                    console.log("Third video loaded successfully")
                  }
                />
              </Stack>
            )}
          </Stack>
          <Stack
            className={Styles.left_title}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            Here's Your Guide to <span>Crafting Effective Product Innovations </span>with Us
           
          </Stack>
        </Stack>

        <Stack className={Styles.formulation_section_part3}>
          <Stack className={Styles.formulation_section_part3_content}>
            <Stack className={Styles.formulation_section_part3_head}>
            We Can be{" "}
              <span className={Styles.formulation_section_part3_head_color}>
              Your Extended Team for Success
              </span>
            </Stack>
            <Stack className={Styles.formulation_section_part3_info}>
              We’re dedicated to your success through clean, honest, and
              innovative formulations. We transform your ideas into products
              that people will love. By combining your marketing skills with our
              manufacturing expertise, let us create standout products together.
              More than just a partner, we are an extension of your team,
              committed to bringing your vision to life.
            </Stack>
            <Stack
              className={Styles.formulation_section_part3_content_contact_btn}
              onClick={() => {
                handleOpen();
              }}
            >
              <span>Get in Touch</span>
            </Stack>
          </Stack>
          <Stack className={Styles.formulation_section_part3_img}>
            <Stack className={Styles.part3_img}>
              
              {/* <img src={CRcon} /> */}
              <LazyLoadImage
                    alt={`img`}
                    src={CRcon}
                  />
            </Stack>
            <Stack className={Styles.part3_img}>
            <LazyLoadImage
                    alt={`img`}
                    src={CruelyIcon}
                  />
              {/* <img src={CruelyIcon} /> */}
            </Stack>
            <Stack className={Styles.part3_img}>
            <LazyLoadImage
                    alt={`img`}
                    src={GMPIcon}
                  />
              {/* <img src={GMPIcon} /> */}
            </Stack>
            <Stack className={Styles.part3_img}>
            <LazyLoadImage
                    alt={`img`}
                    src={IfraIcon}
                  />
              {/* <img src={IfraIcon} /> */}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <ContactUsModal open={open} handleClose={handleClose} />
    </>
  );
};

export default FormulationSection;

import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import Styles from "./OurStorySection.module.css";
import ContactUsModal from "../ContactModal/ContactUsModal";
import RnD from "./../../../assests/rnd.svg";
import Manufacturing from "./../../../assests/manufacturing.svg";
import MissionImage1 from "../../../assests/MissionImage1.mp4";
import Image1 from "../../../assests/photo.png";
import rectangle from "./../../../assests/Rectangle 570.png";
import PosterTag from "./../../../assests/Rectangle 570.png";
import LazyLoadImage from "../../LazyLoadImageProps";

const OurStorySection = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box className={Styles.our_story_container}>
      <Stack className={Styles.bot_content_title}>
      Consider Us as <span>Your Trusted Product Partner</span>
           </Stack>
        <Stack className={Styles.our_story_container_top}>
          {/* <Stack className={Styles.top_img_container}></Stack>{" "} */}
          <Stack className={Styles.left_content} height="auto">
            <Stack className={Styles.left_video} height="100%" width="100%">
              {/* <img
                src={rectangle}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              /> */}
                <LazyLoadImage
                    alt={`Poster`}
                    src={rectangle}
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
              {/* <Stack className={Styles.poster_name}> */}
                {/* <Stack className={Styles.poster_name_text}>
                  "Onesto Lab is built on the foundation of{" "}
                  <p>honest research and exceptional manufacturing</p>. Our
                  Commitiment is to make a positive impact through"every
                  formulation we created."
                  <span style={{ color: "#7cf169" }}> - Vimal Bhola </span>
                  <span style={{ marginTop: "20px" }}>
                    Director of Product Developement <br />
                    (Chief Scientist)
                  </span>
                </Stack> */}
                {/* <img src={PosterTag} />
              </Stack> */}
            </Stack>
          </Stack>
          <Stack className={Styles.top_content}>
            <Stack className={Styles.top_content_layout1}>
              <Stack className={Styles.top_content_layout_head}>
                {" "}
                <Stack className={Styles.top_content_layout_head_img}>
                  <img src={RnD} />
                </Stack>
                <Stack className={Styles.top_content_layout_head_title}>
                  {" "}
                  Research & <span>Development</span>
                </Stack>
              </Stack>
              <Stack className={Styles.top_content_layout_info}>
                Our scientists conduct thorough testing and analysis in the lab
                to create impactful products. We use globally sourced
                ingredients, all backed by scientific research. This ensures
                each product is safe and effective.
              </Stack>
            </Stack>{" "}
            <Stack className={Styles.top_content_layout}>
              <Stack className={Styles.top_content_layout_head}>
                {" "}
                <Stack className={Styles.top_content_layout_head_img}>
                  <img src={Manufacturing} />{" "}
                </Stack>
                <Stack className={Styles.top_content_layout_head_title}>
                  {" "}
                  Manufacturing <span>Unit</span>
                </Stack>
              </Stack>
              <Stack className={Styles.top_content_layout_info}>
                We have a state-of-art manufacturing setup that allows us to
                closely control quality and safety at every stage of product
                development. This ensures that every product meets our high
                standards before it reaches the market.
              </Stack>
            </Stack>{" "}
          </Stack>
        </Stack>

        <Stack className={Styles.our_story_container_bot}>
          <Stack className={Styles.bot_content}>
            <Stack className={Styles.bot_content_title}>
             
              Consider Us as <span>Your Trusted Team </span>for<span> Honest Formulation</span> in R&D and Manufacturing
            </Stack>
            <Stack className={Styles.bot_content_subtitle}>
              At Onesto Labs, honesty is at the heart of everything we do. We
              create formulations that are reliable, effective, and
              results-oriented, ensuring our partners receive premium-quality
              products. Our expert team supports you from start to finish,
              helping you achieve your product goals every step of the way.
            </Stack>
            <Stack
              className={Styles.bot_content_btn}
              onClick={() => {
                handleOpen();
              }}
            >
             Get in Touch
            </Stack>
          </Stack>
          <Stack className={Styles.bot_card}>
            <Stack className={Styles.bot_card_layout}>
              {" "}
              <Stack className={Styles.bot_card_content1}>
                <Stack className={Styles.bot_card_value}>
                  150<span>+</span>
                </Stack>
                <Stack className={Styles.bot_card_title}>
                  Products <br />
                  Manufactured
                </Stack>
              </Stack>
              <Stack className={Styles.bot_card_content2}>
                <Stack className={Styles.bot_card_value}>
                  100<span>+</span>
                </Stack>
                <Stack className={Styles.bot_card_title}>
                  Years of <br />
                  Experience
                </Stack>
              </Stack>
            </Stack>
            <Stack className={Styles.bot_card_layout}>
              {" "}
              <Stack className={Styles.bot_card_content3}>
                <Stack className={Styles.bot_card_value}>
                  20<span>+</span>
                </Stack>
                <Stack className={Styles.bot_card_title}>
                Countries, Ingredients Sourced From
                </Stack>
              </Stack>
              <Stack className={Styles.bot_card_content4}>
                <Stack className={Styles.bot_card_value}>
                  4<span>+</span>
                </Stack>
                <Stack className={Styles.bot_card_title}>
                  Countries We <br />
                  Sell to
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <ContactUsModal open={open} handleClose={handleClose} />
    </>
  );
};

export default OurStorySection;

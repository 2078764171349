import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import AnatonyIcon from "../../../assests/Anatony.svg";
import SunScoopIcon from "../../../assests/sunScoop.svg";
import ChemistAtPlayIcon from "../../../assests/chemistatPlay.svg";
import UgeesIcon from "../../../assests/ugees.svg";
import KoparoIcon from "../../../assests/koparo.svg";
import EthikIcon from "../../../assests/ethik.svg";
import Styles from "./OurClient.module.css";
import LazyLoadImage from "../../LazyLoadImageProps";

const OurClient = () => {
  return (
    <>
      <Box className={Styles.summary_container}>
        <Stack className={Styles.summary_container_content}>
          <Stack className={Styles.summary_container_title}>
            Count on Us for 
            <span className={Styles.summary_container_title_color}>
            &nbsp;Your Formulation Needs
            </span>
          </Stack>
          <Stack className={Styles.summary_container_info}>
            At Onesto Labs, we go beyond manufacturing, we’re your partner in
            crafting a successful brand. With our commitment to honest
            formulations, we bring your product vision to life. Together, by
            combining your marketing know-how with our formulation and
            manufacturing expertise, we create products that resonate with
            consumers and provide lasting value.
          </Stack>
        </Stack>

        <Stack className={Styles.our_client_container_image}>
          <Stack className={Styles.our_client_personal_care}>
            <Stack className={Styles.our_client_container_personal_care_head}>
              Personal Care
            </Stack>
            <Stack className={Styles.our_client_container_personal_care}>
              <Stack className={Styles.box_img}>
              <LazyLoadImage
                   src={AnatonyIcon} alt="Bare Anatomy" 
                  />
                {/* <img src={AnatonyIcon} alt="Bare Anatomy" /> */}
              </Stack>
              <Stack className={Styles.box_img}>
              <LazyLoadImage
                  src={ChemistAtPlayIcon} alt="Chemist at Play"
                  />
                {/* <img src={ChemistAtPlayIcon} alt="Chemist at Play" /> */}
              </Stack>
              <Stack className={Styles.box_img}>
              <LazyLoadImage
                src={SunScoopIcon} alt="Sun Scoop"
                  />
                {/* <img src={SunScoopIcon} alt="Sun Scoop" /> */}
              </Stack>
            </Stack>
          </Stack>

          <Stack className={Styles.our_client_personal_care}>
            <Stack className={Styles.our_client_container_personal_care_head}>
              Hygiene
            </Stack>
            <Stack className={Styles.our_client_container_personal_care}>
              <Stack className={Styles.box_img}>
              <LazyLoadImage
               src={EthikIcon} alt="Ethik" 
                  />
                {/* <img src={EthikIcon} alt="Ethik" />{" "} */}
              </Stack>
              <Stack className={Styles.box_img}>
              <LazyLoadImage
              src={UgeesIcon} alt="ugees"  
                  />
                {/* <img src={UgeesIcon} alt="ugees" /> */}
              </Stack>
              <Stack className={Styles.box_img}>
              <LazyLoadImage
             src={KoparoIcon} alt="Koparo"  
                  />
                {/* <img src={KoparoIcon} alt="Koparo" />{" "} */}
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {/* Moving Brand Images */}
        {/* <Stack className={Styles.summary_container_image}>
          <Stack className={Styles.image_container}>
            <img
              src={AnatonyIcon}
              alt="Bare Anatomy"
              className={
                Styles.moving_image
              } 
            />
            <img src={EthikIcon} alt="Ethik" className={Styles.moving_image} />{" "}
            <img
              src={ChemistAtPlayIcon}
              alt="Chemist at Play"
              className={Styles.moving_image}
            />
            <img
              src={KoparoIcon}
              alt="Koparo"
              className={Styles.moving_image}
            />{" "}
            <img
              src={SunScoopIcon}
              alt="Sun Scoop"
              className={Styles.moving_image}
            />
            <img src={UgeesIcon} alt="ugees" className={Styles.moving_image} />
          </Stack>
        </Stack> */}
      </Box>
    </>
  );
};

export default OurClient;
